import React from 'react';
import '../styles/fontstyle.css'
const StudySelector = ({selectedStudy, onStudyChange }) => {
   const handleStudyChange = (event) => {
      onStudyChange(event.target.value);
   };

   return (
      <div>
         {/* <label htmlFor="fontSelector">Select a font: </label> */}
         <select id="riwayaSelector" value={selectedStudy} onChange={handleStudyChange}
            style={{ width: 100, fontSize: 10 }}>
            <option value="11">hafs uthmani full</option>
            <option value="12">hafs standard 1</option>
            <option value="13">hafs standard 2</option>

            <option value="21">warsh uthmani full</option>
            <option value="22">warsh standard 1</option>
            <option value="23">warsh standard 2</option>

         </select>
      </div>
   );
};

export default StudySelector;