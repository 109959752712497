
import quranImage from '../images/quran.jpg';

const Duaa = () => {
  return (
    <div className="col">
      <h1>قـــــرآن  كريــــــم</h1>
      <img src={quranImage} style={{width:200, height:200}} alt="Example"  />
    </div>
  );
};

export default Duaa;