import React from 'react';
import '../styles/fontstyle.css'
const RiwayaSelector = ({selectedRiwaya, onRiwayaChange }) => {
   const handleRiwayaChange = (event) => {
      onRiwayaChange(event.target.value);
   };

   return (
      <div>
         {/* <label htmlFor="fontSelector">Select a font: </label> */}
         <select id="riwayaSelector" value={selectedRiwaya} onChange={handleRiwayaChange}
            style={{ width: 100, fontSize: 10 }}>
            <option value=" 0"> الرواية </option>
            <option value="11">uthmani</option>
            <option value="12">uthmani full</option>
            <option value="13">uthmani min</option>
            <option value="14">simple</option>
            <option value="15">simple plain</option>
            <option value="16">simple min</option>
            <option value="17">simple clean</option>
            <option value="18">standard 1</option>

            <option value="21">warsh uthmani full</option>
            <option value="22">warsh uthmani </option>
         </select>
      </div>
   );
};

export default RiwayaSelector;