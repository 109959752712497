export function find(list, item) {
  var found = false;
  for (let i = 0; i < list.length; i++) {
    if (list[i] == item) {
      found = true;
      break;
    }
  }
  return found;
}
