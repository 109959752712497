
async function request_script(query,script) {
  const target = "https://rct.tahaleelweb.org/php/" + script
  //console.log("requesting service: ",query)
  try {
    var error = "";
    const response = await
      fetch(target, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify(query),
      }
      );
      //console.log(response)
    if (response.ok) {
      const data = await response.json();
      //console.log(data)
      return [data, ""];
    } else {
      error = 'Error during query:' + response.statusText
      //console.error(error);
      return [{}, error]
    }
  } catch (err) {
    error = `Unexpected error:${err}`
    console.error(error)
    return [{}, error]
  }
}

export async function request_explor(query) {
  const [data,error] = await request_script(query,"get_explor.php");
  return [data,error];
}
export async function request_search(query) {
  //console.log(query);
  const [data,error] = await request_script(query,"get_search.php");
  return [data,error];
}
export async function request_study(query) {
  //console.log(query);
  const [data,error] = await request_script(query,"get_study.php");
  return [data,error];
}
export async function request_riwayat(query) {
  //console.log(query);
  const [data,error] = await request_script(query,"get_riwayat.php");
  return [data,error];
}

