import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMyContext } from '../component/MyContext'
import FontSelector from '../component/FontSelector'
import RiwayaSelector from '../component/RiwayaSelector'
import MsTable from '../component/MsTable'
import {load_cookies,save_cookies} from '../cookies'
import { request_explor } from '../web'
import '../styles/mystyle.css'

function Explor() {
  const [page, setPage] = useState("");
  const [ayat, setAyat] = useState([]);
  const { sura, sura_set, aaya, aaya_set } = useMyContext();
  const [selectedFont, setSelectedFont] = useState('Arabic Typesetting');
  const [selectedRiwaya, setSelectedRiwaya] = useState("12");

  useEffect(
    () => {
      //const headers = ['سورة', 'آية', 'نص'];
      const sura_h = 'سورة ' + sura.toString();
      const headers = ['آية', sura_h];
      setPage(<MsTable head={headers} data={ayat} />)
    }, [ayat, selectedFont])

  useEffect(() => {
    request_page(selectedRiwaya, sura, aaya)
  }, [sura, aaya, selectedRiwaya])

  /*
    useEffect(
      () => {
        console.log("loading cookies")
        loadCookies();
        return () => {
          console.log("saving cookies")
          saveCookies()
        };
      //}, []
      }, 
    );
   */

  const handleFontChange = (font) => {
    setSelectedFont(font)
  }

  async function handleRiwayaChange(riwaya) {
    setSelectedRiwaya(riwaya)
    switch (riwaya) {
      case "11": setSelectedFont("Arabic Typesetting"); break;
      case "12": setSelectedFont("Arabic Typesetting"); break;
      case "13": setSelectedFont("Arabic Typesetting"); break;
      case "14": setSelectedFont("Arabic Typesetting"); break;
      case "15": setSelectedFont("Arabic Typesetting"); break;
      case "16": setSelectedFont("Arabic Typesetting"); break;
      case "17": setSelectedFont("Arabic Typesetting"); break;
      case "18": setSelectedFont("Arabic Typesetting"); break;

      case "21": setSelectedFont("KFGQPC WARSH Uthmanic Script"); break;
      case "22": setSelectedFont("KFGQPC WARSH Uthmanic Script"); break;
      default: // code block
    }
  }

  async function request_page(riwaya, sura, aaya) {
    var data, error
    const query = { "code": 2, "task": 0, "riwaya": riwaya, "sura": sura, "aaya": aaya };
    [data, error] = await request_explor(query)
    if (error !== "") {
      alert(error)
      return
    }
    setAyat(data)
  }

  async function request_index(task, sura, aaya) {
    var data, error
    const query = { "code": 2, "task": task, "sura": sura, "aaya": aaya };
    [data, error] = await request_explor(query)
    if (error !== "") {
      alert(error)
      return
    }
    sura_set(data.sura)
    aaya_set(data.aaya)
  }

  function NextPage() {
    async function handleClick(e) {
      await request_index(1, sura, aaya)
    }
    return (
      <div>
        <button onClick={handleClick}> {">>"} </button>
      </div>
    )
  }

  function PrevPage() {
    async function handleClick(e) {
      await request_index(-1, sura, aaya)
    }
    return (
      <div>
        <button onClick={handleClick}>{"<<"}</button>
      </div>
    )
  }

  function GetFahras() {
    const navigate = useNavigate()
    function handleClick(e) {
      navigate('/fahras')
    }
    return (
      <div>
        <button onClick={handleClick}>فهرس</button>
      </div>
    )
  }

  function DisplayPage() {
      return (
        <div style={{ fontFamily: selectedFont }}>
          {page}
        </div>
      )
    }

  function BtnLoad() {
    async function handleClick() {
      load_cookies(sura_set,aaya_set);
    }
    return (<div><button onClick={handleClick}> load </button></div>);
  };

  function BtnSave() {
    async function handleClick() {
      save_cookies(sura,aaya);
      alert('saved');
    }
    return (<div><button onClick={handleClick}> save </button></div>);
  };

  return (
    <>
      <div className='col'>
        <div className='row'>
          <BtnLoad />
          <PrevPage />
          <NextPage />
          <GetFahras />
          <BtnSave />
        </div>
        <div className='row'>
          <RiwayaSelector selectedRiwaya={selectedRiwaya} onRiwayaChange={handleRiwayaChange} />
          <FontSelector selectedFont={selectedFont} onFontChange={handleFontChange} />
        </div>
        <DisplayPage />
        {/* <div style={{ fontFamily: selectedFont }}> {page} </div> */}
        <div className='row'>
          <PrevPage />
          <NextPage />
        </div>
      </div>
    </>
  )
}

export default Explor


