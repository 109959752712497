import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../component/MyContext';

import '../styles/mystyle.css';
import '../styles/fahras.css';

function Fahras() {
  const navigate = useNavigate();
  const [list, set_list] = useState('');
  const {sura_set, aaya_set, suralist } = useMyContext();

  useEffect(
    () => {
      //console.log("suralist",suralist);
      set_list(<SuratPaint data={suralist} />); 
    }
    , [suralist] 
  );

  function SuratPaint({ data }) {
    function suraClick(boutonNumero) {
      sura_set(boutonNumero);
      aaya_set(1);
      navigate(-1);
    }

    const rows = data.map(item => Object.values(item));
    const boutons = [];
    for (let i = 0; i < data.length; i++) {
      boutons.push(
        <button key={i} onClick={() => suraClick(i + 1, rows[i][2])} className='btn'>
          {rows[i][0]} - {rows[i][1]} ({rows[i][2]})
        </button>
      );
    }
    return (<div className='list'> {boutons} </div>);
  }

  function returnClick(e) {
    e.preventDefault();
    navigate(-1);
  }

  return (
    <div className='col'>
      <button onClick={returnClick}>رجوع</button>
      <div> {list} </div>
    </div>
  );

}

export default Fahras;