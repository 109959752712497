import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useMyContext } from '../component/MyContext'
import FontSelector from '../component/FontSelector'
import StudySelector from '../component/StudySelector'
import MsTable from '../component/MsTable'
import { load_cookies,save_cookies } from '../cookies'
import { request_study } from '../web'
import '../styles/mystyle.css'

function Study() {
  const [page, setPage] = useState("");
  const [ayat, setAyat] = useState([]);
  const { sura, sura_set, aaya, aaya_set } = useMyContext();
  const [selectedFont, setSelectedFont] = useState('Arabic Typesetting');
  const [selectedStudy, setSelectedStudy] = useState("11");

  useEffect(
    () => {
      const sura_h = 'سورة ' + sura.toString();
      const headers = ['آية', sura_h];
      setPage(<MsTable head={headers} data={ayat} />)
    }, [ayat, selectedFont])

  useEffect(() => {
    request_page(selectedStudy, sura, aaya)
  }, [sura, aaya, selectedStudy])


  const handleFontChange = (font) => {
    setSelectedFont(font)
  }

  async function handleStudyChange(study) {
    setSelectedStudy(study)
    switch (study) {
      case "11": setSelectedFont("Arabic Typesetting"); break;
      case "12": setSelectedFont("Arabic Typesetting"); break;
      case "13": setSelectedFont("Arabic Typesetting"); break;
      default: // code block
    }
  }

  async function request_page(study, sura, aaya) {
    var data, error
    const query = { "code": 2, "task": 0, "study": study, "sura": sura, "aaya": aaya };
    //console.log("query:",query.code)
    [data, error] = await request_study(query)
    if (error !== "") {
      alert(error)
      return
    }
    setAyat(data)
    //console.log("query:", query)
  }

  async function request_index(task, sura, aaya) {
    var data, error
    const query = { "code": 2, "task": task, "sura": sura, "aaya": aaya };
    [data, error] = await request_study(query)
    //console.log(query)
    //console.log(data)
    if (error !== "") {
      alert(error)
      return
    }
    sura_set(data.sura)
    aaya_set(data.aaya)
  }

  function NextPage() {
    async function handleClick(e) {
      await request_index(1, sura, aaya)
    }
    return (
      <div>
        <button onClick={handleClick}> {">>"} </button>
      </div>
    )
  }

  function PrevPage() {
    async function handleClick(e) {
      await request_index(-1, sura, aaya)
    }
    return (
      <div>
        <button onClick={handleClick}>{"<<"}</button>
      </div>
    )
  }

  function GetFahras() {
    const navigate = useNavigate()
    function handleClick(e) {
      navigate('/fahras')
    }
    return (
      <div>
        <button onClick={handleClick}>فهرس</button>
      </div>
    )
  }

  function BtnLoad() {
    async function handleClick() {
       load_cookies(sura_set,aaya_set);
    }
    return (<div><button onClick={handleClick}> load </button></div>);
  };

  function BtnSave() {
    async function handleClick() {
      save_cookies(sura,aaya);
    }
    return (<div><button onClick={handleClick}> save </button></div>);
  };

  return (
    <>
      <div className='col'>
        <div className='row'>
          <BtnLoad />
          <PrevPage />
          <NextPage />
          <GetFahras />
          <BtnSave />
        </div>
        <div className='row'>
          <StudySelector selectedStudy={selectedStudy} onStudyChange={handleStudyChange} />
          <FontSelector selectedFont={selectedFont} onFontChange={handleFontChange} />
        </div>
        {/* <DisplayPage /> */}
        <div style={{ fontFamily: selectedFont }}> {page} </div>
        <div className='row'>
          <PrevPage />
          <NextPage />
        </div>
      </div>
    </>
  )
}

export default Study


