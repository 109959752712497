import React from 'react';

const MsTable = ({ head, data }) => {
  const heads = [];
  for (let i = 0; i < head.length; i++) {
    heads.push(<th key={i}> {head[i]} </th>);
  }

  const rows = []
  for (let i = 0; i < data.length; i++) {
    const items = data[i];
    const cols = []
    Object.keys(items).forEach(j => {
      cols.push(<td key={j}>{items[j]}</td>)
    })
    rows.push(<tr key={i}>{cols}</tr>);
  }

  return (
    <table>
      <thead><tr>{heads}</tr></thead>
      <tbody>{rows}</tbody>
    </table>
  );

};

export default MsTable;
