import React, { Component } from 'react';

class ComponLife extends Component {
   constructor(props) {
      super(props);
      // Utilisez `this` pour attribuer les propriétés aux paramètres
      this.load = props.handleload;
      this.unload = props.beforeunload;
   }

   componentDidMount() {
      // Ajouter un écouteur d'événement lors du montage du composant
      window.addEventListener('load', this.load);
      window.addEventListener('beforeunload', this.unload);
   }

   componentWillUnmount() {
      // Retirer l'écouteur d'événement avant que le composant ne soit démonté
      window.removeEventListener('load', this.load);
      window.removeEventListener('beforeunload', this.unload);
   }

   render() {
      return (
         <div>
            {/* Contenu du composant */}
         </div>
      );
   }
}

export default ComponLife;