import React from 'react';

const MyCombobox = ({ list, handleChange, title }) => {
  // État pour la valeur sélectionnée
  // const [selectedValue, setSelectedValue] = useState(''); 

  //Gestionnaire de changement pour le select
  /*   
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };
  */

  // Créer les éléments d'option sans utiliser map
  const renderOptions = () => {
    let options = [];
    options.push(<option key={-1} value={""}> {title} </option>);
    for (let i = 0; i <list.length; i++) {
      options.push(
        <option key={list[i].key} value={list[i].value}> {list[i].value} </option>
      );
    }
    return options;
  };

  return (
    <div>
      {/* <label htmlFor="dynamic-combobox">Choisissez une option :</label> */}
      {/* <select id="dynamic-combobox" value={selectedValue} onChange={handleChange}> */}
      <select id="dynamic-combobox" onChange={handleChange}>
        {renderOptions()}
      </select>
      {/* <p>Option sélectionnée : {selectedValue}</p> */}
    </div>
  );
};

export default MyCombobox;
