import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMyContext } from '../component/MyContext'
import { load_cookies, save_cookies } from '../cookies'
import { request_riwayat } from '../web'
import '../styles/mystyle.css'

function Riwayat() {
  const { aaya, aaya_set, sura, sura_set, suralist } = useMyContext();
  const [page, setPage] = useState("");
  const [ayat, setAyat] = useState([]);

  useEffect(
    () => {
      setPage(<RiwayatPaint data={ayat} />)
    }, [ayat]);

  useEffect(() => {
    request_aaya(2, sura, aaya);
  },
    []
  );

  //const DisplayAya = ({ data }) => {
  function RiwayatPaint({ data }) {
    return (
      <table>
        <thead>
          <tr><th>سورة{data.sura}</th><th>آية{data.aaya}</th></tr>
        </thead>
        <tbody>
          <tr><td>حفص  </td><td style={{ fontFamily: "KFGQPC HafsEx1 Uthmanic Script"}}>{data.hafs}  </td></tr>
          <tr><td>ورش  </td><td style={{ fontFamily: "KFGQPC WARSH Uthmanic Script"  }}>{data.warsh} </td></tr>
          <tr><td>بزّي  </td><td style={{ fontFamily: "KFGQPC BAZZI Uthmanic Script"  }}>{data.bazzi} </td></tr>
          <tr><td>دوري </td><td style={{ fontFamily: "KFGQPC DOORI Uthmanic Script"  }}>{data.doori} </td></tr>
          <tr><td>قالون</td><td style={{ fontFamily: "KFGQPC QALOON Uthmanic Script" }}>{data.qaloon}</td></tr>
          <tr><td>قنبل </td><td style={{ fontFamily: "KFGQPC QUMBUL Uthmanic Script" }}>{data.qumbul}</td></tr>
          <tr><td>شعبة </td><td style={{ fontFamily: "KFGQPC SHOUBA Uthmanic Script" }}>{data.shouba}</td></tr>
          <tr><td>سوسي </td><td style={{ fontFamily: "KFGQPC SOOSI Uthmanic Script"  }}>{data.soosi} </td></tr>
        </tbody >
      </table >
    );
  };

  function AayaInput({ val }) {

    const handleInputChange = (e) => {
      const v = e.target.value;
      if (v <= suralist[sura - 1][2]) {
        aaya_set(v);
        request_aaya(2, sura, v);
      }
    };

    return (
      <div>
        <label> آيــة </label>
        <input
          type="number" style={{ width: 60 }}
          value={val}
          min={1}
          //max={maxim}
          onChange={handleInputChange}
        />
      </div>
    );
  };


  async function request_aaya(task, sura, aaya) {
    var data, error
    const query = { "code": task, "sura": sura, "aaya": aaya };
    //console.log("query:",query.code)
    [data, error] = await request_riwayat(query)
    if (error !== "") {
      alert(error)
      return
    }
    //console.log(data);
    sura_set(data.sura);
    aaya_set(data.aaya);
    setAyat(data);
    //console.log('ayat:', ayat);
  }

  function NextAaya() {
    async function handleClick(e) {
      await request_aaya(4, sura, aaya);
    }
    return (
      <div>
        <button onClick={handleClick}> {">>"} </button>
      </div>
    )
  }

  function PrevAaya() {
    async function handleClick(e) {
      await request_aaya(3, sura, aaya);
    }
    return (
      <div>
        <button onClick={handleClick}>{"<<"}</button>
      </div>
    )
  }

  function GetFahras() {
    const navigate = useNavigate()
    function handleClick(e) {
      navigate('/fahras')
    }
    return (
      <div>
        <button onClick={handleClick}>فهرس</button>
      </div>
    )
  }

  function BtnLoad() {
    async function handleClick() {
      load_cookies(sura_set, aaya_set);
    }
    return (<div><button onClick={handleClick}> load </button></div>);
  };

  function BtnSave() {
    async function handleClick() {
      save_cookies(sura, aaya);
      alert('saved');
    }
    return (<div><button onClick={handleClick}> save </button></div>);
  };

  return (
    <>
      <div className='col'>
        <div className='row'>
          <BtnLoad />
          <PrevAaya />
          <AayaInput val={aaya} />
          <NextAaya />
          <GetFahras />
          <BtnSave />
        </div>
        <div> {page} </div>
        <div className='row'>
          <PrevAaya />
          <NextAaya />
        </div>
      </div>
    </>
  )
}
export default Riwayat