import React, { useEffect, useState } from 'react';
import SearchSelector from '../component/SearchSelector';
import MyList from '../component/MyList';
import MyCombobox from '../component/MyCombobox';
import SearchAyat from '../component/SearchAyat';

import { request_search } from '../web';
import '../styles/mystyle.css';

function Search() {
  const [search, setSearch] = useState("1");
  const [word, setWord] = useState("رحمة");
  const [kalimat, setKalimat] = useState({});
  const [ayat, setAyat] = useState({});

  const head = ['سورة', 'آية', 'نص'];

  const [huruf, setHuruf] = useState({});
  const [aalam, setAalam] = useState({});
  const [juthur, setJuthur] = useState({});
  const [hosna, setHosna] = useState({});

  const handleElement = (event) => {
    setWord(event.target.value);
  };


  async function initiate() {
    var data, error
    [data, error] = await request_search({ "code": 201 }); setHuruf(data);
    if (error !== "") { alert(error); return; };
    [data, error] = await request_search({ "code": 202 }); setAalam(data);
    if (error !== "") { alert(error); return; };
    [data, error] = await request_search({ "code": 203 }); setJuthur(data);
    if (error !== "") { alert(error); return; };
    [data, error] = await request_search({ "code": 204 }); setHosna(data);
    if (error !== "") { alert(error); return; };
  }

  useEffect(() => {
    initiate()
  }, [])

  const handleSearchChange = (choice) => {
    setSearch(choice)
  }
  const handleWordChange = (event) => {
    setWord(event.target.value)
  }

  async function searchClick() {
    var ayat1, klmt1, error
    //console.log('word :', word)
    //console.log('search :', search);
    switch (search) {
      case "1":
        [ayat1, error] = await request_search({ "code": 301, "word": word });
        [klmt1, error] = await request_search({ "code": 311, "word": word });
        break;
      case "2":
        [ayat1, error] = await request_search({ "code": 302, "word": word });
        [klmt1, error] = await request_search({ "code": 312, "word": word });
        break;
      case "3":
        [ayat1, error] = await request_search({ "code": 303, "word": word });
        [klmt1, error] = await request_search({ "code": 313, "word": word });
        break;
      default: ;
    }
    if (error !== "") { alert(error); return; };
    setAyat(ayat1);
    setKalimat(klmt1);
  };


  return (
    <div className='col' style={{ fontSize: 14 }}>
      <div className='row'>
        <MyCombobox list={huruf} handleChange={handleElement} title="حروف" />
        <MyCombobox list={aalam} handleChange={handleElement} title="أعلام" />
        <MyCombobox list={juthur} handleChange={handleElement} title="جذور" />
        <MyCombobox list={hosna} handleChange={handleElement} title="حسنى" />
      </div>

      <br />
      <div>
        <label> المفردة </label>
        <input type="text" value={word} onChange={handleWordChange} style={{ width: 50 }} />
      </div>
      <SearchSelector selectedSearch={search} onSearchChange={handleSearchChange} />
      <button onClick={searchClick}> بـــحـــث </button>
      <br />
      <label> عدد الكلمات: {kalimat.length} </label>
      <MyList data={kalimat} />
      <br />
      <label> عدد الآيات {ayat.length} </label>
      {/* <MsTable head={head} data={ayat} /> */}
      <SearchAyat head={head} ayat={ayat} kalimat={kalimat} />
    </div>
  )
};
export default Search; 